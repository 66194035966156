<!--
 * @Description: 待审企业
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-15 10:48:46
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" class="no-mg-b" :inline="true" @submit.prevent="onSearch">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="名称"
          style="width: 240px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder="请选择"
          clearable
          style="width: 120px"
        >
          <el-option label="无" value="0"></el-option>
          <el-option label="审核中" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search" native-type="submit"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="danger"
          round
          size="small"
          icon="delete"
          :disabled="selection_list.length <= 0"
          v-if="is_can_del"
          @click="onDeleteBatch"
          :loading="deleting"
          >批量删除</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table
    ref="table"
      :data="tableData.data"
      border
      stripe
      row-key="id"
      @sort-change="onSort"
      @selection-change="onSelectionChange"
    >
      <el-table-column
        width="50"
        reserve-selection
        type="selection"
      ></el-table-column>
      <el-table-column
        label="ID"
        prop="id"
        width="90"
        sortable="custom"
        :resizable="false"
      ></el-table-column>
      <el-table-column
        label="公司名称"
        prop="companyName"
        min-width="300"
      ></el-table-column>
      <el-table-column
        label="法人姓名"
        prop="personName"
        width="150"
      ></el-table-column>
      <el-table-column
        label="法人手机"
        prop="personMobile"
        width="150"
      ></el-table-column>
      <el-table-column label="状态" width="100" prop="status_text">
        <template #default="scope">
          <el-link
            type="danger"
            size="small"
            v-if="scope.row.checkStatus == 2"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="success"
            size="small"
            v-else-if="scope.row.checkStatus == 1"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="primary"
            size="small"
            v-else-if="scope.row.checkStatus == 3"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link size="small" v-else :underline="false">{{
            scope.row.status_text
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        label="账号限制"
        prop="accountCounts"
        width="100"
      ></el-table-column>
      <el-table-column
        label="注册时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-button
            type="success"
            size="small"
            icon="check"
            link
            v-if="scope.row.checkStatus == 0 || scope.row.checkStatus == 3"
            @click="onCheck(scope.row)"
            >审核</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-company
    :company="current_item"
    @success="onEditSuccess"
    @closed="current_item = null"
  ></edit-company>
  <check-company
    :id="currentCheckId"
    @closed="currentCheckId = null"
    @success="loadData"
  ></check-company>
</template>

<script>
import common_api from "@/http/common_api";
import CheckCompany from "../check_company.vue";
import EditCompany from "../edit_company.vue";

export default {
  components: {
    CheckCompany,
    EditCompany,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "descending",
          prop: "id",
        },
        checkStatus: [0, 3],
        q: "",
      },
      tableData: {
        counts: 0,
      },
      selection_list: [],
      currentCheckId: null,
      current_item: null,
      is_can_edit: false,
      is_can_check: false,
      is_can_del: false,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    this.is_can_edit = this.$power("admin/v1/company/edit");
    this.is_can_check = this.$power("admin/v1/company/check");
    this.is_can_del = this.$power("admin/v1/company/del");

    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("/admin/v1/company", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(o) {
      if (o.prop) {
        this.query.sort = {
          order: o.order,
          prop: o.prop,
        };
        this.onSearch();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/company/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          if (this.query.pageIndex > 1) {
            if (this.tableData.data.length <= 1) {
              this.query.pageIndex--;
            }
          }
          this.loadData();
        }
      });
    },

    /**
     * 编辑修改
     */
    onEdit(item) {
      this.current_item = item ? item : {};
    },

    /**
     * 编辑成功回调
     */
    onEditSuccess() {
      this.current_item = null;
      this.loadData();
    },
    /**
     * 审核
     */
    onCheck(item) {
      this.currentCheckId = item.id;
    },

    /**
     * 多选
     */
    onSelectionChange(rows) {
      this.selection_list = rows;
    },

    /**
     *  批量删除
     */
    onDeleteBatch() {
      this.$confirm("您确实要删除选择的公司？", "温馨提示").then(() => {
        this.deleting = true;
        var ids = this.selection_list.map((x) => x.id);
        this.$http
          .post(`admin/v1/company/del_batch`, ids)
          .then((res) => {
            if (res.code == 0) {
              this.$refs.table.clearSelection();
              this.loadData();
            }
          })
          .finally(() => (this.deleting = false));
      });
    },
  },
};
</script>

<style scoped></style>
